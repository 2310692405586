import aspida from "libs/aspida";
import useAspidaSWR from "@aspida/swr";
import { useAtom } from "jotai";

import { Methods as postLessonRequestComment } from "api/api/v1/lesson_requests/_lessonRequestId/lesson_request_comments";
import { Methods as postSelectMeetingPossibleDatetimesMethods } from "api/api/v1/lessons/_lessonId@string/lesson_meeting_possible_datetimes/select_datetime";
import { Methods as UpdateLessonMethods } from "api/api/v1/lessons/_lessonId@string";
import type { Methods as UploadSlideMethods } from "api/api/v1/lessons/_lessonId@string/lesson_slides";
import type { Methods as UpdateSlideMethods } from "api/api/v1/lessons/_lessonId@string/lesson_slides/_lessonSlideId@string";

import { currentUserAtom } from "store/AuthStore";

import useHeaders from "hooks/useHeaders";
import useTeacherCommonData from "hooks/useTeacherCommonData";
import { useAlertMessages } from "hooks/useAlertMessages";
import {
  detailsAtom,
  schoolTeacherAtom,
  supporterAtom,
  lessonRequestCommentsAtom,
  meetingSheetInLessonAtom,
  ratingsAtom,
  lessonMeetingPossibleDatetimesAtom,
  bankAccountAtom,
  isQuestionnaireAnsweredAtom,
  lessonSlideAtom
} from "./store";

type postRequestCommentsType = postLessonRequestComment["post"]["reqBody"];
export type postSelectMeetingPossibleDatetimesType =
  postSelectMeetingPossibleDatetimesMethods["post"]["reqBody"];

const useShowPageApi = (lessonId: string) => {
  const headers = useHeaders();
  const { addErrorMessage, addSuccessMessage } = useAlertMessages();
  const currentUser = useAtom(currentUserAtom)[0];

  const [details, setDetails] = useAtom(detailsAtom);
  const [, setSchoolTeacher] = useAtom(schoolTeacherAtom);
  const [, setSupporter] = useAtom(supporterAtom);
  const [, setMeetingSheetInLesson] = useAtom(meetingSheetInLessonAtom);
  const [, setRequestComments] = useAtom(lessonRequestCommentsAtom);
  const [, setRatings] = useAtom(ratingsAtom);
  const [, setLessonMeetingPossibleDatetimes] = useAtom(
    lessonMeetingPossibleDatetimesAtom
  );
  const [, setBankAccount] = useAtom(bankAccountAtom);
  const [, setIsQuestionnaireAnswered] = useAtom(isQuestionnaireAnsweredAtom);
  const [lessonSlide, setLessonSlide] = useAtom(lessonSlideAtom);

  const { mutate: mutateTeacherCommonData } = useTeacherCommonData();

  const { isLoading: getLessonIsLoading, mutate: mutateLessonData } =
    useAspidaSWR(
      // eslint-disable-next-line no-underscore-dangle
      aspida(headers)
        .api.v1.teachers._teacherId_string(currentUser.id.toString())
        .lessons._lessonId(lessonId),
      {
        onSuccess: (data) => {
          setDetails(data.details);
          setLessonMeetingPossibleDatetimes(
            data.details.lesson_meeting_possible_datetimes
          );
          setSchoolTeacher(data.send_user);
          setSupporter(data.supporter);
          setMeetingSheetInLesson(data.agenda_sheet);
          setIsQuestionnaireAnswered(data.is_questionnaire_answered);
        },
        onError: () => {
          addErrorMessage("授業情報の取得に失敗しました");
        },
        enabled: !!currentUser.id
      }
    );

  const updateLesson = async (
    data: UpdateLessonMethods["patch"]["reqBody"]
  ) => {
    try {
      // eslint-disable-next-line no-underscore-dangle
      await aspida(headers)
        .api.v1.lessons._lessonId_string(lessonId)
        .patch({ body: data });
      mutateLessonData();
      // MEMO: データの送り方に合わせてメッセージを変更
      if (data.meeting_place || data.meeting_url) {
        addSuccessMessage("打ち合わせ場所を登録しました");
      }
      if (data.lesson_completed_at) {
        addSuccessMessage("授業の完了報告を受け付けました");
      }
    } catch (error) {
      if (data.lesson_completed_at) {
        addErrorMessage("授業の完了報告に失敗しました");
      }
      addErrorMessage("授業情報の更新に失敗しました");
    }
  };

  // for bank account
  useAspidaSWR(
    aspida(headers).api.v1.teachers._teacherId_string(
      currentUser.teacher_profile_id.toString()
    ).teacher_bank_accounts,
    {
      onSuccess: (data) => {
        setBankAccount(data.teacher_bank_account);
      }
    }
  );

  // for chat
  const {
    isLoading: getRequestCommentsIsLoading,
    mutate: mutateRequestComments
  } = useAspidaSWR(
    // eslint-disable-next-line no-underscore-dangle
    aspida(headers).api.v1.lesson_requests._lessonRequestId(
      details?.lesson_request_id?.toString() || ""
    ).lesson_request_comments,
    {
      refreshInterval: 60000,
      onSuccess: (data) => {
        setRequestComments(data);
      }
      // MEMO: 必ず1度目にエラーが出るため一旦コメントアウト
      // onError: () => addErrorMessage("メッセージの取得に失敗しました")
    }
  );

  const postRequestComments = async (data: postRequestCommentsType) => {
    try {
      // eslint-disable-next-line no-underscore-dangle
      await aspida(headers)
        .api.v1.lesson_requests._lessonRequestId(
          details?.lesson_request_id?.toString() || ""
        )
        .lesson_request_comments.post({
          body: data
        });

      mutateRequestComments();

      addSuccessMessage("メッセージを送信しました");
    } catch (error) {
      addErrorMessage("メッセージの送信に失敗しました");
    }
  };

  const deleteRequestComments = async (commentId: string) => {
    try {
      // eslint-disable-next-line no-underscore-dangle
      await aspida(headers)
        .api.v1.lesson_requests._lessonRequestId_string(
          details?.lesson_request_id?.toString() || ""
        )
        .lesson_request_comments._lessonRequestCommentId(commentId)
        .logical_delete.patch({});

      mutateRequestComments();

      addSuccessMessage("メッセージを削除しました");
    } catch (error) {
      addErrorMessage("メッセージの削除に失敗しました");
    }
  };

  const logicalDeleteUnreadMessages = async () => {
    try {
      // eslint-disable-next-line no-underscore-dangle
      await aspida(headers)
        .api.v1.lessons._lessonId_string(lessonId)
        .unread_messages.multi_update.put({ body: { is_read: true } });

      await mutateTeacherCommonData();
      await mutateRequestComments();
    } catch (error) {
      console.error(error);
    }
  };

  // for rating
  useAspidaSWR(
    // eslint-disable-next-line no-underscore-dangle
    aspida(headers).api.v1.lessons._lessonId_string(lessonId).ratings,
    {
      onSuccess: (data) => {
        setRatings(data.details);
      },
      // MEMO: 今後リクエストのリトライ処理は共通化する
      onErrorRetry: (err, key, config, revalidate, revalidateOpts) => {
        if (err.response.status === 404) return;

        // MEMO: 3回リトライしてもダメならエラーメッセージを表示
        if (revalidateOpts.retryCount >= 3) {
          addErrorMessage("評価情報の取得に失敗しました");
          return;
        }

        setTimeout(() => revalidate(revalidateOpts), 1000);
      },
      onError: () => {
        setRatings(undefined);
      }
    }
  );

  // 授業スライドの取得
  const { mutate: mutateSlides } = useAspidaSWR(
    aspida(headers).api.v1.lessons._lessonId_string(lessonId).lesson_slides,
    {
      onSuccess: (data) => {
        if (data.length > 0) {
          // MEMO: スライドはDB的には1対多だが、仕様上は1つだけの前提
          setLessonSlide(data[0]);
        } else {
          setLessonSlide(undefined);
        }
      }
    }
  );

  // for meeting schedules
  const postSelectMeetingPossibleDatetimes = async (
    data: postSelectMeetingPossibleDatetimesType
  ) => {
    try {
      await aspida(headers)
        .api.v1.lessons._lessonId_string(lessonId)
        .lesson_meeting_possible_datetimes.select_datetime.post({
          body: data
        });
      if (data.is_all_reject) {
        addSuccessMessage("日程の再設定を依頼しました");
      } else {
        addSuccessMessage("日程を選択しました");
      }
      mutateLessonData();
    } catch (error) {
      addErrorMessage("日程の選択に失敗しました");
    }
  };

  // スライドのアップロード
  const handleUploadSlide = async (
    data: UploadSlideMethods["post"]["reqBody"]
  ) => {
    try {
      const res = await aspida(headers)
        .api.v1.lessons._lessonId_string(lessonId)
        .lesson_slides.post({
          body: data
        });
      if (res.status === 200) {
        addSuccessMessage("スライドをアップロードしました");
        mutateSlides();
      }
    } catch (error) {
      addErrorMessage("スライドのアップロードに失敗しました");
    }
  };

  // スライドの更新
  const handleUpdateSlide = async (
    data: UpdateSlideMethods["patch"]["reqBody"]
  ) => {
    try {
      const res = await aspida(headers)
        .api.v1.lessons._lessonId_string(lessonId)
        .lesson_slides._lessonSlideId(lessonSlide?.id.toString() || "")
        .patch({
          body: data
        });
      if (res.status === 200) {
        addSuccessMessage("スライド情報を更新しました");
        mutateSlides();
      }
    } catch {
      addErrorMessage("スライドの更新に失敗しました");
    }
  };

  const handleDeleteSlide = async () => {
    if (!lessonSlide?.id) return;
    try {
      const res = await aspida(headers)
        .api.v1.lessons._lessonId_string(lessonId)
        .lesson_slides._lessonSlideId(lessonSlide?.id.toString() || "")
        .delete();
      if (res.status === 200) {
        addSuccessMessage("スライドを削除しました");
        mutateSlides();
      }
    } catch {
      addErrorMessage("スライドの削除に失敗しました");
    }
  };

  return {
    getLessonIsLoading,
    getRequestCommentsIsLoading,
    mutateRequestComments,
    postRequestComments,
    deleteRequestComments,
    logicalDeleteUnreadMessages,
    postSelectMeetingPossibleDatetimes,
    updateLesson,
    handleUploadSlide,
    handleUpdateSlide,
    handleDeleteSlide
  };
};

export default useShowPageApi;
