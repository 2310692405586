import useCurrentTeacher from "hooks/useCurrentTeacher";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";

import {
  isLoggedInAtom,
  currentUserAtom,
  prevLoginPathAtom
} from "store/AuthStore";
import { schoolIsLoggedInAtom } from "store/SchoolAuthStore";

function PrivateRoute(props: any) {
  const { mutate } = useCurrentTeacher();
  const isLoggedIn = useAtom(isLoggedInAtom)[0];
  const currentUser = useAtom(currentUserAtom)[0];
  const schoolIsLoggedIn = useAtom(schoolIsLoggedInAtom)[0];
  const [, setPrevLoginPath] = useAtom(prevLoginPathAtom);

  // 登録後はユーザー情報が変わるので、強制的に再取得する
  useEffect(() => {
    if (props.path === "/teachers/profile/complete") {
      mutate();
    }
  }, [props.path, mutate]);

  if (schoolIsLoggedIn) {
    setPrevLoginPath(props.location.pathname + props.location.search);
    return <Redirect to="/teachers/login" />;
  }

  if (!isLoggedIn) {
    setPrevLoginPath(props.location.pathname + props.location.search);
    return <Redirect to="/teachers/login" />;
  }

  if (
    !currentUser.teacher_profile_id &&
    props.path !== "/teachers/profile/to-new" &&
    props.path !== "/teachers/profile/new" &&
    props.path !== "/teachers/profile/complete"
  ) {
    setPrevLoginPath(props.location.pathname + props.location.search);
    return <Redirect to="/teachers/profile/to-new" />;
  }

  setPrevLoginPath("");
  return <Route {...props} />;
}

export default PrivateRoute;
