import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import dayjs from "libs/dayjs";

import type React from "react";
import FormLabel from "components/atoms/FormLabel";
import TextField from "components/atoms/fields/TextField";
import { useFormContext } from "react-hook-form";
import IndicatePrivate from "components/atoms/fieldLabels/IndicatePrivate";

type FormBirthdayFieldProps = {
  name: string;
  label: string;
  isRequired?: boolean;
  placeholders: {
    year: string;
    month: string;
    date: string;
  };
  supplementalText?: string;
  isPrivate?: boolean;
};

const FormBirthdayField: React.FC<FormBirthdayFieldProps> = ({
  name,
  label,
  isRequired,
  placeholders,
  supplementalText,
  isPrivate
}) => {
  const {
    getValues,
    setValue,
    formState: { errors, isDirty }
  } = useFormContext();

  const error = errors[name];

  const birthday = getValues(name) ? dayjs(getValues(name)) : "";
  const [birthOfYear, setBirthOfYear] = useState(
    birthday ? birthday.year().toString() : ""
  );
  const [birthOfMonth, setBirthOfMonth] = useState(
    birthday ? (birthday.month() + 1).toString() : ""
  );
  const [birthOfDate, setBirthOfDate] = useState(
    birthday ? birthday.date().toString() : ""
  );
  const theme = useTheme();

  const handleYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setBirthOfYear(value);
    if (birthOfMonth && birthOfDate) {
      setValue(name, `${value}-${birthOfMonth}-${birthOfDate}`, {
        shouldValidate: true,
        shouldDirty: true
      });
    }
  };

  const handleMonthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setBirthOfMonth(value);
    if (birthOfYear && birthOfDate) {
      setValue(name, `${birthOfYear}-${value}-${birthOfDate}`, {
        shouldValidate: true,
        shouldDirty: true
      });
    }
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setBirthOfDate(value);
    if (birthOfYear && birthOfMonth) {
      setValue(name, `${birthOfYear}-${birthOfMonth}-${value}`, {
        shouldValidate: true,
        shouldDirty: true
      });
    }
  };

  useEffect(() => {
    if (birthday && dayjs(birthday).isValid() && !isDirty) {
      setBirthOfYear(dayjs(birthday).get("year").toString());
      setBirthOfMonth((dayjs(birthday).get("month") + 1).toString());
      setBirthOfDate(dayjs(birthday).get("date").toString());
    }
  }, [birthday]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: ".5rem"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: "0.25rem"
        }}
      >
        <FormLabel labelName={label} isRequired={isRequired} />
        {supplementalText && (
          <Typography variant="caption" sx={{ color: theme.palette.sub.main }}>
            {supplementalText}
          </Typography>
        )}
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <TextField
          variant="outlined"
          placeholder={placeholders.year}
          value={birthOfYear}
          onChange={handleYearChange}
          error={!!error}
          sx={{ width: "80px" }}
        />
        <Typography sx={{ margin: "0 .5rem" }}>年</Typography>
        <TextField
          variant="outlined"
          placeholder={placeholders.month}
          value={birthOfMonth}
          onChange={handleMonthChange}
          error={!!error}
          sx={{ width: "60px" }}
        />
        <Typography sx={{ margin: "0 .5rem" }}>月</Typography>
        <TextField
          variant="outlined"
          placeholder={placeholders.date}
          value={birthOfDate}
          onChange={handleDateChange}
          error={!!error}
          sx={{ width: "60px" }}
        />
        <Typography sx={{ margin: "0 .5rem" }}>日</Typography>
      </Box>

      {error && (
        <Typography color="error" variant="caption">
          {error.message?.toString()}
        </Typography>
      )}
      {isPrivate && <IndicatePrivate />}
    </Box>
  );
};

export default FormBirthdayField;
