import { Download } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import Button from "components/atoms/mui/Button";
import CommonDialog from "components/molecules/CommonDialog";
import SlidePreview from "components/molecules/SlidePreview";
import { CommonDialogProps } from "types/commonDialogProps";

type SlideShowModalProps = {
  url: string;
} & CommonDialogProps;

const SlideShowModal: React.FC<SlideShowModalProps> = ({
  url,
  onClose,
  isOpen
}) => {
  const handleDownload = () => {
    window.open(url, "_blank");
    onClose();
  };

  return (
    <CommonDialog open={isOpen} onClose={onClose}>
      <Stack spacing={2}>
        <Typography variant="h2">授業スライド</Typography>
        <SlidePreview fileUrl={url} />
        {/* ダウンロードボタン */}
        <Button
          startIcon={<Download />}
          variant="contained"
          color="primary"
          onClick={handleDownload}
        >
          ダウンロード
        </Button>
      </Stack>
    </CommonDialog>
  );
};

export default SlideShowModal;
