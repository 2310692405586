import HatIcon from "assets/schoolLp/HatIcon.png";
import SearchIcon from "assets/schoolLp/SearchIcon.png";
import LightIcon from "assets/schoolLp/LightIcon.png";
import ProcedureImage01 from "assets/schoolLp/procedure-1.png";
import ProcedureImage02 from "assets/schoolLp/procedure-2.png";
import ProcedureImage03 from "assets/schoolLp/procedure-3.png";
import ProcedureImage04 from "assets/schoolLp/procedure-4.png";
import { ExampleBoxProps } from "./molecules/ExampleBox";
import { CurriculumItemProps } from "./molecules/CurriculumItem";

export const ISSUE_ITEMS = [
  {
    icon: SearchIcon,
    text: "探究学習で魅力的な\n教育活動をしたいが\nどうすればいいかわからない"
  },
  {
    icon: HatIcon,
    text: "キャリア教育で\n生徒の選択肢の\n幅が狭くなっている"
  },
  {
    icon: LightIcon,
    text: "起業家教育を\nやりたいけど\nどう教えていいかわからない"
  }
];

export const CASE_ITEMS = [
  {
    videoUrl:
      "https://fukugyo-sensei-bucket.s3.ap-northeast-1.amazonaws.com/classi/861.mp4",
    title: "科学を学んだ先にあるキャリア",
    avatarUrl:
      "https://fukugyo-sensei-bucket.s3.amazonaws.com/uploads/teacher_profile/image/861/4clbsgb8.png",
    organization: "株式会社村田製作所",
    name: "増本優衣"
  },
  {
    videoUrl:
      "https://fukugyo-sensei-bucket.s3.ap-northeast-1.amazonaws.com/classi/965.mp4",
    title: "そもそも探究ってなに？探究学習ことはじめ",
    avatarUrl:
      "https://fukugyo-sensei-bucket.s3.amazonaws.com/uploads/teacher_profile/image/965/15ocv5f4.png",
    organization: "一般社団法人ココカラスタジオ",
    name: "中田愛"
  },
  {
    videoUrl:
      "https://fukugyo-sensei-bucket.s3.ap-northeast-1.amazonaws.com/classi/515.mp4",
    title: "海外大学に進学するには？",
    avatarUrl:
      "https://fukugyo-sensei-bucket.s3.amazonaws.com/uploads/teacher_profile/image/515/7hkuf2l3.png",
    organization: "株式会社U-LABO",
    name: "小泉涼輔"
  }
];

export const EXAMPLE_ITEMS: ExampleBoxProps[] = [
  {
    title: "わかりやすい内容で\n子どもたちも興味をひかれてました",
    desc: "「面白がる」の具体例がCMで冒頭部に示され、子どもたちは興味をひかれていました。また地域おこし協力隊のお話もしてくださったため、一緒に参観したコミュニティスクール委員の方々にも実感を持って受け止めていただける内容でした。こちらのサイトを使ってのやり取りは簡易でよかったです。",
    rating: 5,
    teacherName: "新潟県公立中学校教員",
    link: "https://note.com/lxdesign/n/n44f5c577de4c"
  },
  {
    title: "生徒参加型の授業で\n生徒と教員の理解が深まりました。",
    desc: "こちらがお願いした意図を汲んだ内容で、生徒や教員から「探究学習がわかってきた」との声が挙がっていました。実験やグループワークを導入したことで、生徒が楽しく考えられる環境を作っていただき感謝しております。本校の生徒に合わせた対応も素晴らしく、多様な事例を交えた熱意ある講義でした。",
    teacherName: "東京都公立中学校教員",
    rating: 5,
    link: "https://note.com/lxdesign/n/n658b95489c28"
  }
];

export const CURRICULUM_ITEMS: CurriculumItemProps[] = [
  {
    category: "キャリア",
    lessons: [
      {
        title: "キャリアロールモデル",
        desc: "複業先生のキャリアを講演や対話を通して自分の目指したいキャリアのロールモデルに出会う・気づくための活動を行う"
      }
    ]
  },
  {
    category: "探求学習\n地方創生",
    lessons: [
      {
        title: "地元の複業先生からの講演",
        desc: "学校区に居住する複業先生から地域の魅力や課題について提示してもらう"
      },
      {
        title: "アイディア創発ワークショップ",
        desc: "自分の住む地域の魅力をアピールするためのアイディア出し〜ポスター等の制作活動を行う"
      },
      {
        title: "プレゼン作成ワークショップ",
        desc: "情報を人に伝えるために必要な要素（スライド作成のコツ・発表時の姿勢）を学び、最終発表のための準備を行う"
      },
      {
        title: "探究成果発表会",
        desc: "年間の探究活動の総括として、地域関係者を招待し、プレゼン発表を実施する"
      }
    ]
  },
  {
    category: "探究学習\nテーマ型",
    lessons: [
      {
        title: "講演",
        desc: "講師の専門性を活かしたテーマで、社会問題・現在の仕事内容・価値観などについて講演"
      },
      {
        title: "複業先生から課題出題",
        desc: "講師の講演に関連した探究テーマの出題。「海洋プラスチックを本気で減らすために、とるべき施策とは？」「本当に幸福な社会を実現するためには？」等"
      },
      {
        title: "生徒の探究学習",
        desc: "複業先生の出題した課題に対して、生徒がフィールドワークや調査活動を行う"
      },
      {
        title: "メンタリング・フィードバック",
        desc: "生徒の探究学習に対して、複業先生が1人メンタリングや、グループ発表へのフィードバック(FB)を実施"
      }
    ]
  }
];

export const PROCEDURE_ITEMS = [
  {
    image: ProcedureImage01,
    title: "無料登録",
    description:
      "複業先生として活動するため、簡単なプロフィールを記入し会員登録をします。事務局の審査通過後、授業ができるようになります。"
  },
  {
    image: ProcedureImage02,
    title: "授業を作成する",
    description:
      "学校から授業依頼が来たり、また自分から興味のある授業に応募することができます。相互で合意すれば授業成立です。"
  },
  {
    image: ProcedureImage03,
    title: "打ち合わせ",
    description:
      "学校と日程調整後、授業に向けて打ち合わせを行います。授業内容などのすり合わせをし、ワクワクする授業を作っていきましょう。"
  },
  {
    image: ProcedureImage04,
    title: "授業実施",
    description:
      "打ち合わせた内容をもとに授業を実施します。授業後はアンケートを記入して、複業先生のお仕事完了です。"
  }
];
