import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import type React from "react";
import CommonDialog from "components/molecules/CommonDialog";
import { CommonDialogProps } from "types/commonDialogProps";
import { Methods } from "api/api/v1/teachers/_teacherId@string";
import SlidePreview from "components/molecules/SlidePreview";

type LessonRequestType = Methods["get"]["resBody"]["lesson_requests"][number];

type PastLessonModalProps = {
  timelines?: LessonRequestType["timelines"];
  lessonSlide?: LessonRequestType["lesson_slides"];
} & CommonDialogProps;

const PastLessonModal: React.FC<PastLessonModalProps> = (props) => {
  const { timelines, lessonSlide, isOpen, onClose } = props;

  return (
    <CommonDialog open={isOpen} onClose={onClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          margin: "1rem 0"
        }}
      >
        {timelines && timelines?.length > 0 && (
          <>
            <Typography variant="h4">授業の時間配分</Typography>
            {timelines.map((timeline, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem"
                }}
              >
                <Typography sx={{ flex: 1 }}>
                  <Typography
                    component="span"
                    fontSize="1.2rem"
                    sx={{
                      marginRight: ".2rem"
                    }}
                  >
                    {timeline.minutes}
                  </Typography>
                  <Typography component="span" fontSize=".8rem">
                    分
                  </Typography>
                </Typography>
                <Typography fontWeight="bold" sx={{ flex: 5 }}>
                  {timeline.title}
                </Typography>
              </Box>
            ))}
          </>
        )}
        {lessonSlide && lessonSlide.length > 0 && (
          <>
            <Typography variant="h4">授業スライド</Typography>
            <SlidePreview fileUrl={lessonSlide[0].file.url} />
          </>
        )}
      </Box>
    </CommonDialog>
  );
};

export default PastLessonModal;
