import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import LaunchIcon from "@mui/icons-material/Launch";
import PastLessonModal from "features/teachers/id/organisms/PastLessonModal";

import type React from "react";
import { Methods } from "api/api/v1/teachers/_teacherId@string";
import { useDialog } from "hooks/useDialog";

type PastClassCardProps = {
  lessonRequest: Methods["get"]["resBody"]["lesson_requests"][number];
};

const PastClassCard: React.FC<PastClassCardProps> = (props) => {
  const { lessonRequest } = props;
  const pastLessonModal = useDialog();
  const muiTheme = useTheme();

  return (
    <>
      <Box
        sx={{
          backgroundColor: muiTheme.palette.backgroundColor?.main,
          borderRadius: "8px",
          border: `solid 1px ${muiTheme.palette.border?.main}`,
          width: "240px",
          padding: "1rem",
          cursor: "pointer"
        }}
        onClick={pastLessonModal.onOpen}
      >
        <Typography fontWeight="bold" color={muiTheme.palette.textColor.main}>
          {lessonRequest.school_grade}へ{lessonRequest.theme}の授業
          <LaunchIcon
            sx={{
              color: muiTheme.palette.muted?.main,
              fontSize: "1rem",
              verticalAlign: "middle",
              marginLeft: ".2rem"
            }}
          />
        </Typography>
      </Box>
      <PastLessonModal
        {...pastLessonModal}
        timelines={lessonRequest.timelines}
        lessonSlide={lessonRequest.lesson_slides}
      />
    </>
  );
};

export default PastClassCard;
