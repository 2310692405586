import { ArrowBack, ArrowForward } from "@mui/icons-material";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useState } from "react";
import { Page, Document, pdfjs } from "react-pdf";
import { customColors } from "themes/customColors";

type SlidePreviewProps = {
  fileUrl: string;
};

// PDF.js のワーカーを指定（CORS対策）
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const SlidePreview: React.FC<SlidePreviewProps> = ({ fileUrl }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // PDF のプレビュー用
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  return (
    <Box>
      <Box border={`1px solid ${customColors.border.main}`} mb={1}>
        <Document
          file={fileUrl}
          onLoadSuccess={({ numPages }) => setNumPages(numPages)}
        >
          <Page pageNumber={pageNumber} width={isMobile ? 250 : 500} />
        </Document>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Button
          size="small"
          variant="outlined"
          onClick={() => setPageNumber((prev) => Math.max(prev - 1, 1))}
          disabled={pageNumber === 1}
          startIcon={<ArrowBack />}
        >
          前のページ
        </Button>
        <Typography>
          {pageNumber} / {numPages}
        </Typography>
        <Button
          size="small"
          variant="outlined"
          onClick={() =>
            setPageNumber((prev) => Math.min(prev + 1, numPages ?? 1))
          }
          disabled={pageNumber === numPages}
          endIcon={<ArrowForward />}
        >
          次のページ
        </Button>
      </Box>
    </Box>
  );
};

export default SlidePreview;
