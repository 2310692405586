import { Stack, useTheme, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import IssueSp from "assets/schoolLp/IssueSp.png";
import IssuePc from "assets/schoolLp/IssuePc.png";

import type React from "react";
import Achievement from "features/root/index/molecules/Achievement";
import { ISSUE_ITEMS } from "../constants";

const Issue: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box component="section" padding="80px 24px">
      <Stack spacing={4} alignItems="center">
        <Typography align="center">
          生徒たちに“科目の枠を超えた学習の場”を。
          <br />
          教育現場と外部人材をつなぎ、
          {isMobile && <br />}
          授業までサポートします。
        </Typography>
        <Box
          component="img"
          src={isMobile ? IssueSp : IssuePc}
          alt="issue"
          maxWidth={isMobile ? "100%" : "900px"}
          width="100%"
        />
        <Achievement />
      </Stack>
      <Stack
        direction="column"
        spacing={3}
        mt={isMobile ? "80px" : "120px"}
        alignItems={isMobile ? "left" : "center"}
      >
        <Typography variant="h3" align="center">
          このようなお悩みを持った学校におすすめです
        </Typography>
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={3}
          alignItems={isMobile ? "left" : "center"}
          justifyContent={isMobile ? "left" : "space-between"}
          maxWidth="672px"
        >
          {ISSUE_ITEMS.map((item, index) => (
            <Stack
              key={index}
              direction={isMobile ? "row" : "column"}
              spacing={2}
              alignItems="center"
            >
              <Box
                component="img"
                src={item.icon}
                alt="issue"
                width="96px"
                height="96px"
                sx={{
                  backgroundColor: "#FAF9F7",
                  padding: "16px",
                  borderRadius: "50%"
                }}
              />
              <Typography
                whiteSpace="pre-wrap"
                align={isMobile ? "left" : "center"}
              >
                {item.text}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};

export default Issue;
