import { useTheme, useMediaQuery, Box, Divider } from "@mui/material";
import TopPageTitle from "features/root/index/molecules/TopPageTitle";
import { Fragment } from "react";
import { CURRICULUM_ITEMS } from "../constants";
import CurriculumItem from "../molecules/CurriculumItem";

const Curriculum: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      component="section"
      py="80px"
      px={isMobile ? "24px" : "80px"}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "40px",
        alignItems: "center"
      }}
    >
      <TopPageTitle
        title="CURRICULUM"
        subText={["授業のカリキュラム例"]}
        grey
      />
      {CURRICULUM_ITEMS.map((item, index) => (
        <Fragment key={index}>
          <CurriculumItem {...item} key={index} />
          {!isMobile && index !== CURRICULUM_ITEMS.length - 1 && (
            <Divider flexItem />
          )}
        </Fragment>
      ))}
    </Box>
  );
};

export default Curriculum;
