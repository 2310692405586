import Typography from "@mui/material/Typography";

import type React from "react";
import { Box } from "@mui/material";
import { Fragment } from "react";

type TopPageTitleProps = {
  title: string;
  subText: string[];
  grey?: boolean;
};

const TopPageTitle: React.FC<TopPageTitleProps> = (props) => {
  const { title, subText, grey } = props;

  return (
    <Box
      sx={{
        width: "100%"
      }}
    >
      <Typography
        variant="h2"
        align="center"
        sx={{
          color: grey ? "#EFEDE8" : "#FFEFC7",
          fontSize: "2.4rem !important",
          lineHeight: "1.6 !important"
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="h3"
        align="center"
        sx={{
          lineHeight: "1.6 !important"
        }}
      >
        {subText.map((element, index) => (
          <Fragment key={index}>
            <span key={element}>{element}</span>
            <br />
          </Fragment>
        ))}
      </Typography>
    </Box>
  );
};

export default TopPageTitle;
