import {
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import Box from "@mui/material/Box";

import TopImage from "assets/schoolLp/lp-top.png";
import HeaderLogo from "assets/HeaderLogoSvg.svg";
import LessonImage1 from "assets/schoolLp/LessonImage1.png";
import LessonImage2 from "assets/teacherLp/lp-teacher-top-2.png";
import LessonImage3 from "assets/teacherLp/intro-lesson.png";

import type React from "react";
import { useHistory } from "react-router-dom";

const FirstView: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();

  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        backgroundColor: "#FFF5DD"
      }}
    >
      <Stack
        direction={isMobile ? "column" : "row"}
        spacing={2}
        maxWidth="1200px"
        padding="48px 40px 0 40px"
      >
        <Stack spacing={3} alignItems="center" pb={1} flexShrink={0}>
          <Typography
            variant="h1"
            fontSize="20px"
            fontWeight="bold"
            align="center"
          >
            外部人材を活用した授業を
            {isMobile && <br />}
            もっと気軽に。
          </Typography>
          <img
            src={HeaderLogo}
            alt="logo"
            style={{
              width: isMobile ? "250px" : "300px"
            }}
          />
          <Button
            size="large"
            onClick={() => history.push("/signup?active=school")}
          >
            まずは学校アカウント作成(無料)
          </Button>
        </Stack>
        <Box
          sx={{
            width: { xs: "100%", md: "60%" },
            display: "flex",
            justifyContent: "center"
          }}
        >
          <img
            src={TopImage}
            alt="TopImage"
            style={{
              width: "100%",
              maxWidth: "100%",
              height: "auto",
              zIndex: 1,
              objectFit: "contain"
            }}
          />
        </Box>
      </Stack>
      <Stack
        direction="row"
        maxWidth="100%"
        height={isMobile ? "120px" : "268px"}
        sx={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <Box
          component="img"
          src={LessonImage1}
          sx={{
            flex: 1,
            width: "33%",
            height: "auto",
            objectFit: "cover"
          }}
        />
        {!isMobile && (
          <Box
            component="img"
            src={LessonImage2}
            sx={{
              flex: 1,
              width: "33%",
              height: "auto",
              objectFit: "cover"
            }}
          />
        )}
        <Box
          component="img"
          src={LessonImage3}
          sx={{
            flex: 1,
            width: "33%",
            height: "auto",
            objectFit: "cover"
          }}
        />
      </Stack>
    </Box>
  );
};

export default FirstView;
