import React, { useState, useEffect } from "react";
import {
  TextField,
  Box,
  FormHelperText,
  TextFieldProps as MuiTextFieldProps
} from "@mui/material";
import { customColors } from "../../../themes/customColors";

type TextAreaProps = {
  maxCharacters?: number;
} & MuiTextFieldProps;

const TextArea: React.FC<TextAreaProps> = ({
  maxCharacters,
  value = "",
  onChange,
  helperText,
  error,
  ...props
}) => {
  const [currentValue, setCurrentValue] = useState<string>(
    (value as string) || ""
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  const isError = error;
  const errorText = isError ? helperText : "";
  const countText = `${currentValue.length}/${maxCharacters}`;

  // FIXME: このコンポーネント自体が複数回に渡ってレンダリングされるため、回避のための対応
  useEffect(() => {
    setCurrentValue((value as string) || "");
  }, [value]);

  return (
    <Box position="relative">
      <TextField
        {...props}
        value={currentValue}
        onChange={handleChange}
        error={isError}
        multiline
        fullWidth
        minRows={props.minRows || 3}
      />
      {(errorText || maxCharacters) && (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {errorText && (
            <FormHelperText
              error={isError}
              sx={{
                flex: 1,
                justifyContent: "flex-start",
                color: customColors.error.main
              }}
            >
              {errorText}
            </FormHelperText>
          )}
          {maxCharacters && !props.disabled && (
            <FormHelperText
              sx={{
                flex: 1,
                justifyContent: "flex-end",
                color:
                  currentValue.length > (maxCharacters ?? 0)
                    ? customColors.error.main
                    : "inherit",
                textAlign: "right"
              }}
            >
              {countText}
            </FormHelperText>
          )}
        </Box>
      )}
    </Box>
  );
};

export default TextArea;
