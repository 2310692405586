import { Box, Stack, Typography } from "@mui/material";
import Button from "components/atoms/mui/Button";
import CommonDialog from "components/molecules/CommonDialog";
import FormSelect from "components/molecules/FormSelect";
import { FormProvider, useForm } from "react-hook-form";
import { CommonDialogProps } from "types/commonDialogProps";
import { useEffect, useState } from "react";
import { Delete } from "@mui/icons-material";

import type { Methods } from "api/api/v1/lessons/_lessonId@string/lesson_slides";
import SlidePreview from "components/molecules/SlidePreview";

type UploadSlideModalProps = {
  title: string;
  handleUpload: (data: Methods["post"]["reqBody"]) => void;
  isFinished: boolean;
} & CommonDialogProps;

const UploadSlideModal: React.FC<UploadSlideModalProps> = ({
  title,
  onClose,
  isOpen,
  handleUpload,
  isFinished
}) => {
  const [fileUrl, setFileUrl] = useState<string | null>(null);

  // ファイルのバリデーションエラーのため、yupを不使用
  const form = useForm<Methods["post"]["reqBody"]>({
    mode: "onChange",
    defaultValues: {
      is_public: isFinished
    }
  });
  const { watch, setValue, reset } = form;
  const file = watch("file");

  // ファイル選択時の処理
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setValue("file", selectedFile, { shouldValidate: true });
      setFileUrl(URL.createObjectURL(selectedFile));
    }
  };

  useEffect(() => {
    setValue("is_public", isFinished);
  }, [isFinished, setValue]);

  return (
    <CommonDialog open={isOpen} onClose={onClose}>
      <FormProvider {...form}>
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            p: 2
          }}
          onSubmit={form.handleSubmit((data) => {
            handleUpload(data);
            reset();
            setFileUrl(null);
            onClose();
          })}
        >
          <Typography variant="h2">{title}</Typography>
          <Typography variant="caption">
            PDFファイルのみアップロードできます
          </Typography>

          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontWeight="bold">スライド</Typography>
            {file && (
              <Button
                size="small"
                variant="text"
                color="error"
                startIcon={<Delete />}
                onClick={() => {
                  setValue("file", undefined);
                  setFileUrl(null);
                  setValue("is_public", undefined);
                }}
              >
                選択を解除
              </Button>
            )}
          </Stack>

          {!fileUrl ? (
            // ファイル選択ボタン
            <Button size="small" variant="outlined" component="label">
              ファイルを選択
              <input
                type="file"
                accept=".pdf"
                hidden
                onChange={handleFileChange}
              />
            </Button>
          ) : (
            <SlidePreview fileUrl={fileUrl} />
          )}
          {isFinished && (
            <FormSelect
              name="is_public"
              label="公開設定"
              options={[
                { value: true, label: "公開" },
                { value: false, label: "非公開" }
              ]}
              noLabel
              // 授業が終了していない場合は選択できない
              disabled={!file || !isFinished}
              supplementalText="※公開範囲はシステムに登録されている学校の先生のみになります"
            />
          )}
          <Typography variant="caption">
            公開する前に、著作権侵害にあたるものや学校の機密情報やが含まれていないかご確認ください。公開が難しそうな情報が含まれている場合は、事前に学校へ確認をお願いします。
          </Typography>
          {/* yupのバリデーションを使用していないため、通常のボタンを使用 */}
          <Button type="submit" disabled={!file}>
            アップロード
          </Button>
        </Box>
      </FormProvider>
    </CommonDialog>
  );
};

export default UploadSlideModal;
