import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import LeafLeft from "assets/teacherLp/leaf-left.svg";
import LeafRight from "assets/teacherLp/leaf-right.svg";

const Achievement: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const leafImageStyle = {
    width: isMobile ? "19px" : "24px",
    height: isMobile ? "45px" : "58px"
  };

  return (
    <Stack spacing={0.5}>
      <Stack direction="row" spacing={1.5}>
        {/* 左のデータ表示 */}
        <Stack direction="row" spacing={1}>
          <img src={LeafLeft} alt="leaf-left" style={leafImageStyle} />
          <Stack direction="column" alignItems="center">
            <Typography fontWeight="bold" fontSize={{ xs: "12px", sm: "14px" }}>
              複業先生登録者
            </Typography>
            <Typography color="primary">
              <span
                style={{
                  fontSize: isMobile ? "28px" : "32px",
                  fontWeight: "800"
                }}
              >
                2,459
              </span>
              <span
                style={{
                  fontSize: isMobile ? "18px" : "24px",
                  fontWeight: "800"
                }}
              >
                名
              </span>
            </Typography>
          </Stack>
          <img src={LeafRight} alt="leaf-right" style={leafImageStyle} />
        </Stack>
        {/* 右のデータ表示 */}
        <Stack direction="row" spacing={1}>
          <img src={LeafLeft} alt="leaf-left" style={leafImageStyle} />
          <Stack direction="column" alignItems="center">
            <Typography fontWeight="bold" fontSize={{ xs: "12px", sm: "14px" }}>
              授業参加生徒
              <span
                style={{
                  fontSize: isMobile ? "11px" : "14px",
                  fontWeight: "normal"
                }}
              >
                （累計）
              </span>
            </Typography>
            <Typography color="primary">
              <span
                style={{
                  fontSize: isMobile ? "25px" : "32px",
                  fontWeight: "800"
                }}
              >
                25,629
              </span>
              <span
                style={{
                  fontSize: isMobile ? "18px" : "24px",
                  fontWeight: "800"
                }}
              >
                名
              </span>
            </Typography>
          </Stack>
          <img src={LeafRight} alt="leaf-right" style={leafImageStyle} />
        </Stack>
      </Stack>
      <Typography variant="caption" align={isMobile ? "left" : "center"}>
        ※2024年12月時点
      </Typography>
    </Stack>
  );
};

export default Achievement;
