import { Stack, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import TopPageTitle from "features/root/index/molecules/TopPageTitle";

import type React from "react";
import { EXAMPLE_ITEMS } from "../constants";
import ExampleBox from "../molecules/ExampleBox";

const Example: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      component="section"
      py="80px"
      px={isMobile ? "24px" : "80px"}
      sx={{
        backgroundColor: "#FFF6DE",
        display: "flex",
        flexDirection: "column",
        gap: "40px",
        alignItems: "center"
      }}
    >
      <TopPageTitle title="CASE" subText={["過去の授業例"]} />
      <Stack spacing={3} direction={isMobile ? "column" : "row"}>
        {EXAMPLE_ITEMS.map((item, index) => (
          <ExampleBox {...item} key={index} />
        ))}
      </Stack>
    </Box>
  );
};

export default Example;
