import { atom } from "jotai";

import { Methods } from "api/api/v1/school_teachers/_schoolTeacherId@string/lessons/_lessonId@string";
import { Methods as LessonRequestCommentsMethods } from "api/api/v1/lesson_requests/_lessonRequestId/lesson_request_comments";
import { Methods as RatingMethods } from "api/api/v1/lessons/_lessonId@string/ratings";
import { Methods as LessonSlideMethods } from "api/api/v1/lessons/_lessonId@string/lesson_slides";

export const detailsAtom = atom<
  Methods["get"]["resBody"]["details"] | undefined
>(undefined);

export const teacherAtom = atom<
  Methods["get"]["resBody"]["teacher"] | undefined
>(undefined);

export const supporterAtom = atom<
  Methods["get"]["resBody"]["supporter"] | undefined
>(undefined);

// MEMO: 紐付け先変更のタイミングで調整
export const lessonRequestCommentsAtom = atom<
  LessonRequestCommentsMethods["get"]["resBody"] | undefined
>(undefined);

export const meetingSheetInLessonAtom = atom<
  Methods["get"]["resBody"]["agenda_sheet"] | undefined
>(undefined);

export const ratingsAtom = atom<
  RatingMethods["get"]["resBody"]["details"] | undefined
>(undefined);

export const lessonSlideAtom = atom<
  LessonSlideMethods["get"]["resBody"][number] | undefined
>(undefined);

export const activeStepAtom = atom((get) => {
  const details = get(detailsAtom);

  switch (details?.step) {
    case "suggest_meeting_date":
    case "select_meeting_date":
    case "adjust_meeting_place":
    case "before_meeting":
      return 1;
    case "preparation":
      if (
        details?.is_skip_lesson_preliminary_report ||
        details?.lesson_preliminary_report_completed_at
      ) {
        return 3;
      }
      return 2;
    case "lesson_end":
    case "completed":
      if (
        details?.is_skip_after_lesson_report ||
        details?.after_lesson_report_completed_at
      ) {
        return 5;
      }
      return 4;
    default:
      return 0;
  }
});

// MEMO: かかる金額の内訳を返却するAtom
export const lessonAmountWithoutOptionsAtom = atom((get) => {
  const details = get(detailsAtom);

  let optionsAmount = 0;

  if (!details) {
    return 0;
  }

  details.lesson_options.forEach((option) => {
    optionsAmount += option.amount;
  });

  return details.amount - optionsAmount;
});
