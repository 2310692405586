import { Stack, useTheme, Link, Rating } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { ReactComponent as RatingStarIcon } from "assets/Star.svg";
import { ReactComponent as RatingStarEmptyIcon } from "assets/StarEmpty.svg";

import type React from "react";

export type ExampleBoxProps = {
  title: string;
  desc: string;
  link: string;
  rating: number;
  teacherName: string;
};

const ExampleBox: React.FC<ExampleBoxProps> = (props) => {
  const { title, desc, link, rating, teacherName } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: "32px",
        padding: "32px",
        maxWidth: "480px"
      }}
    >
      <Stack spacing={2}>
        <Rating
          value={rating}
          readOnly
          emptyIcon={<RatingStarEmptyIcon width="24px" height="24px" />}
          icon={<RatingStarIcon width="24px" height="24px" />}
          sx={{
            "& .MuiRating-iconFilled": {
              mx: "1px"
            }
          }}
        />
        <Typography variant="h3" whiteSpace="pre-wrap" lineHeight="180%">
          {title}
        </Typography>
        <Typography>{desc}</Typography>
        <Typography variant="h4" fontWeight="bold">
          {teacherName}
        </Typography>
        <Link href={link} color={theme.palette.secondary.light} fontSize="16px">
          この授業の記事へ（外部サイト）
        </Link>
      </Stack>
    </Box>
  );
};

export default ExampleBox;
