import { Box, Stack, Typography } from "@mui/material";
import Button from "components/atoms/mui/Button";
import CommonDialog from "components/molecules/CommonDialog";
import FormSelect from "components/molecules/FormSelect";
import { FormProvider, useForm } from "react-hook-form";
import { CommonDialogProps } from "types/commonDialogProps";
import { useEffect, useRef, useState } from "react";
import { Delete, Edit } from "@mui/icons-material";

import type { Methods } from "api/api/v1/lessons/_lessonId@string/lesson_slides/_lessonSlideId@string";
import { useAtomValue } from "jotai";
import SlidePreview from "components/molecules/SlidePreview";
import { lessonSlideAtom } from "../store";

type UploadSlideModalProps = {
  title: string;
  handleUpdate: (data: Methods["patch"]["reqBody"]) => void;
  handleDelete: () => void;
  isFinished: boolean;
  // 公開設定の選択を表示するか
  selectIsPublic?: boolean;
} & CommonDialogProps;

const UploadSlideModal: React.FC<UploadSlideModalProps> = ({
  title,
  onClose,
  isOpen,
  handleUpdate,
  handleDelete,
  isFinished,
  selectIsPublic = false
}) => {
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const lessonSlide = useAtomValue(lessonSlideAtom);

  // react-hook-form の初期化
  const form = useForm<Methods["patch"]["reqBody"]>({
    mode: "onChange"
  });
  const {
    setValue,
    getValues,
    formState: { isDirty }
  } = form;

  // 隠しのファイル入力用の ref を作成
  const fileInputRef = useRef<HTMLInputElement>(null);

  // ファイル選択時の処理
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setValue("file", selectedFile, {
        shouldValidate: true,
        shouldDirty: true
      });
      setFileUrl(URL.createObjectURL(selectedFile));
    }
  };

  // スライドを公開する
  const handlePublish = () => {
    handleUpdate({
      ...getValues(),
      is_public: true
    });
    onClose();
  };

  useEffect(() => {
    if (lessonSlide && !isDirty) {
      form.reset({
        // file はリセットしない（ファイルの変更がなければ送らない）
        is_public: lessonSlide.is_public
      });
      setFileUrl(lessonSlide.file.url);
    }
  }, [lessonSlide, isOpen]);

  return (
    <CommonDialog open={isOpen} onClose={onClose}>
      <FormProvider {...form}>
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            p: 2
          }}
          onSubmit={form.handleSubmit((data) => {
            handleUpdate(data);
            onClose();
          })}
        >
          <Typography variant="h2">{title}</Typography>
          <Typography variant="caption">
            PDFファイルのみアップロードできます
          </Typography>

          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontWeight="bold">スライド</Typography>
            {fileUrl && (
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
              >
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<Edit />}
                  onClick={() => {
                    // ファイル入力の値をクリアしてからクリック
                    if (fileInputRef.current) {
                      fileInputRef.current.value = "";
                      fileInputRef.current.click();
                    }
                  }}
                >
                  スライドを変更
                </Button>
                <Button
                  size="small"
                  variant="text"
                  color="error"
                  startIcon={<Delete />}
                  onClick={handleDelete}
                >
                  スライドを削除
                </Button>
              </Stack>
            )}
          </Stack>

          {!fileUrl ? (
            // ファイル選択ボタン（隠しの input を利用）
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                if (fileInputRef.current) {
                  fileInputRef.current.value = "";
                  fileInputRef.current.click();
                }
              }}
            >
              ファイルを選択
            </Button>
          ) : (
            <SlidePreview fileUrl={fileUrl} />
          )}

          {isFinished && !selectIsPublic && (
            <FormSelect
              name="is_public"
              label="公開設定"
              options={[
                { value: true, label: "公開" },
                { value: false, label: "非公開" }
              ]}
              noLabel
              // 授業が終了していない場合は選択できない
              disabled={!fileUrl || !isFinished}
              supplementalText="※公開範囲はシステムに登録されている学校の先生のみになります"
            />
          )}
          <Typography variant="caption">
            公開する前に、著作権侵害にあたるものや学校の機密情報やが含まれていないかご確認ください。公開が難しそうな情報が含まれている場合は、事前に学校へ確認をお願いします。
          </Typography>
          {selectIsPublic ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: 1
              }}
            >
              <Button size="small" variant="text" onClick={onClose} fullWidth>
                非公開のままにする
              </Button>
              <Button disabled={!fileUrl} onClick={handlePublish} fullWidth>
                公開する
              </Button>
            </Box>
          ) : (
            /* 変更があれば更新可能 */
            <Button type="submit" disabled={!isDirty || !fileUrl}>
              保存する
            </Button>
          )}

          {/* 隠しのファイル入力 */}
          <input
            type="file"
            accept=".pdf"
            hidden
            ref={fileInputRef}
            onChange={handleFileChange}
          />
        </Box>
      </FormProvider>
    </CommonDialog>
  );
};

export default UploadSlideModal;
