import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import FormCheckBox from "components/molecules/FormCheckBox";

import Typography from "@mui/material/Typography";
import type React from "react";
import { Stack, useTheme } from "@mui/material";
import { Methods } from "api/api/v1/lesson_requests/_lessonRequestId/lesson_request_comments";
import postChatFormSchema from "constants/lessons/postChatFormShema";
import FormSubmitButton from "components/molecules/FormSubmitButton";

import { useDialog } from "hooks/useDialog";
import FormTextArea from "components/molecules/FormTextArea";
import IconPopover from "components/atoms/IconPopover";
import FileUploadLoading from "./FileUploadLoading";
import FileUploadModal from "./FileUploadModal";

type MessageFieldProps = {
  postRequestComments: (data: Methods["post"]["reqBody"]) => any;
  supporter?: string;
};

const MessageField: React.FC<MessageFieldProps> = (props) => {
  const { postRequestComments, supporter } = props;
  const fileUploadModal = useDialog();
  const theme = useTheme();
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(postChatFormSchema)
  });

  const onSubmit = methods.handleSubmit(async (data) => {
    await postRequestComments(data);
    // ファイルの送信時はファイルのみリセット
    if (data.file) {
      methods.resetField("file");
    }
    // メッセージのみの送信時はメッセージのみリセット
    if (!data.file && data.comment) {
      methods.resetField("comment");
    }
  });

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={onSubmit} py={1}>
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Button
              variant="text"
              startIcon={
                <InsertDriveFileOutlinedIcon sx={{ fontSize: ".8rem" }} />
              }
              onClick={fileUploadModal.onOpen}
              sx={{
                padding: 0,
                color: theme.palette.link?.main,
                textDecoration: "underline"
              }}
            >
              ファイル送信
            </Button>
            <Stack direction="row" alignItems="center">
              <FormCheckBox
                name="is_office_notification"
                label={
                  supporter
                    ? `サポート担当(${supporter})にも送信`
                    : "サポート担当者にも送信"
                }
              />
              <IconPopover
                variant="question"
                text="サポート担当者にもメッセージを送信したい場合はチェックしてください"
              />
            </Stack>
          </Stack>
          <Stack gap={2} direction="row" alignItems="center">
            <Box sx={{ flexGrow: 1 }}>
              <FormTextArea
                name="comment"
                label=""
                placeholder="メッセージを入力してください"
                noLabel
                maxRows={5}
                minRows={1}
              />
              {methods.formState.errors.file?.message && (
                <Typography color={theme.palette.error.main} fontSize=".8rem">
                  {methods.formState.errors.file?.message.toString()}
                </Typography>
              )}
            </Box>
            <FormSubmitButton label="送信" size="small" />
          </Stack>
        </Stack>
      </Box>
      <FileUploadModal
        isOpen={fileUploadModal.isOpen}
        onClose={fileUploadModal.onClose}
        onSubmit={onSubmit}
      />
      <FileUploadLoading
        isSubmitting={
          methods.formState.isSubmitting && !!methods.getValues("file")
        }
      />
    </FormProvider>
  );
};

export default MessageField;
