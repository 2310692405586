import Meta from "components/organisms/Meta";
import Conclusion from "features/root/lp/school/organisms/Conclusion";
import FirstView from "features/root/lp/school/organisms/FirstView";
import Issue from "features/root/lp/school/organisms/Issue";
import Procedures from "features/root/lp/school/organisms/Procedures";
import Example from "features/root/lp/school/organisms/Example";
import CommonLayout from "components/layouts/CommonLayout";

import type React from "react";
import Case from "./organisms/Case";
import Curriculum from "./organisms/Curriculum";

const SchoolHome: React.FC = () => (
  <CommonLayout>
    <Meta
      title="出前授業といえば複業先生|多様なキャリアの社会人が在籍"
      description="出前授業の依頼プラットフォームです。複業先生を使うことで多種多様な経歴を持った1000人以上の外部人材の方に学校での授業依頼をすることができます。金融、地方創生、起業など様々な授業依頼が可能です。"
    />
    <FirstView />
    <Issue />
    <Example />
    <Case />
    <Curriculum />
    <Procedures />
    <Conclusion />
  </CommonLayout>
);

export default SchoolHome;
