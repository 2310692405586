import {
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";

export type CurriculumItemProps = {
  category: string;
  lessons: {
    title: string;
    desc: string;
  }[];
};

const CurriculumItem = ({ category, lessons }: CurriculumItemProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Stack
      direction={isMobile ? "column" : "row"}
      spacing={2}
      maxWidth="960px"
      width="100%"
    >
      <Box
        sx={{
          width: isMobile ? "100%" : "120px",
          minWidth: "120px",
          display: "flex",
          alignItems: "center"
        }}
      >
        <Typography
          variant="h4"
          whiteSpace={isMobile ? "normal" : "pre-wrap"}
          fontSize="18px"
        >
          {category}
        </Typography>
      </Box>
      {!isMobile && <Divider orientation="vertical" flexItem />}
      <Grid
        container
        rowSpacing={isMobile ? 2 : 0}
        columnSpacing={isMobile ? 0 : 2}
      >
        {lessons.map((lesson, index) => (
          <Grid item xs={12} md={3} key={index}>
            <Stack spacing={2} direction={isMobile ? "row" : "column"}>
              <Box
                sx={{
                  backgroundColor: "#7D7670",
                  borderRadius: "4px",
                  width: isMobile ? "48px" : "100%",
                  minWidth: "48px",
                  frexShrink: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: isMobile ? "26px 4px" : "4px"
                }}
              >
                <Typography
                  color="white"
                  fontWeight="bold"
                  fontSize={isMobile ? "12px" : "16px"}
                >
                  <span
                    style={{
                      fontSize: "18px",
                      display: isMobile ? "block" : "inline",
                      textAlign: "center"
                    }}
                  >
                    {index + 1}
                  </span>
                  コマ目
                </Typography>
              </Box>
              <Stack spacing={1}>
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  whiteSpace="pre-wrap"
                  align={isMobile ? "left" : "center"}
                >
                  {lesson.title}
                </Typography>
                <Typography variant="body1">{lesson.desc}</Typography>
              </Stack>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default CurriculumItem;
