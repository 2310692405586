import React from "react";
import { useAtomValue } from "jotai";
import Container from "@mui/material/Container";
import CommonLayout from "components/layouts/CommonLayout";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import useTheme from "@mui/material/styles/useTheme";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";

import Meta from "components/organisms/Meta";

import { useParams } from "react-router-dom";

import Chat from "components/organisms/lessons/Chat";
import SetMeetingPlaceBlock from "components/organisms/lessons/SetMeetingPlaceBlock";
import BeforeMeetingBlock from "components/organisms/lessons/BeforeMeetingBlock";
import LessonPlaceBlock from "components/organisms/lessons/LessonPlaceBlock";
import CustomTabSection from "components/organisms/CustomTabSection";
import { useMediaQuery } from "@mui/material";
import { useDialog } from "hooks/useDialog";
import dayjs from "dayjs";
import useShowPageApi from "./useApi";

import LessonFlowSection from "./organisms/LessonFlowSection";
import LessonInfoSection from "./organisms/LessonInfoSection";
import SelectCandidateBlock from "./organisms/SelectCandidateBlock";
import WaitingForMeetingSuggetionBlock from "./organisms/WaitingForMeetingSuggetionBlock";
import PreparationList from "./organisms/PreparationList";
import LessonCompleteReportBlock from "./organisms/LessonCompleteReportBlock";
import LessonCompletedBlock from "./organisms/LessonCompletedBlock";

import {
  detailsAtom,
  lessonRequestCommentsAtom,
  lessonSlideAtom
} from "./store";
import UploadSlideModal from "./organisms/UploadSlideModal";
import UpdateSlideModal from "./organisms/UpdateSlideModal";

type paramsTypes = {
  id: string;
};

const Show: React.FC = () => {
  const uploadSlideModal = useDialog();
  const updateSlideModal = useDialog();
  const details = useAtomValue(detailsAtom);
  const lessonSlide = useAtomValue(lessonSlideAtom);
  const lessonRequestComments = useAtomValue(lessonRequestCommentsAtom);
  const { id } = useParams<paramsTypes>();
  const theme = useTheme();
  // チャットのみ表示切り替えを480pxのbreakpointで行う
  const isMobile = useMediaQuery("(max-width: 480px)");

  const {
    getLessonIsLoading,
    postRequestComments,
    deleteRequestComments,
    logicalDeleteUnreadMessages,
    postSelectMeetingPossibleDatetimes,
    updateLesson,
    handleUploadSlide,
    handleUpdateSlide,
    handleDeleteSlide
  } = useShowPageApi(id);

  const handleLessonComplete = () => {
    updateLesson({
      lesson_completed_at: dayjs().format()
    });
    // スライドがアップロードされていない場合はアップロードモーダルを表示
    if (!lessonSlide) {
      uploadSlideModal.onOpen();
    }
    // スライドが非公開の場合は更新モーダルを表示
    else if (!lessonSlide.is_public) {
      updateSlideModal.onOpen();
    }
  };

  const getTaskBlock = () => {
    switch (details?.step) {
      case "suggest_meeting_date":
        return <WaitingForMeetingSuggetionBlock />;
      case "select_meeting_date":
        return (
          <SelectCandidateBlock
            postSelectMeetingPossibleDatetimes={
              postSelectMeetingPossibleDatetimes
            }
          />
        );
      case "adjust_meeting_place":
        return <SetMeetingPlaceBlock updateLesson={updateLesson} />;
      case "before_meeting":
        return <BeforeMeetingBlock />;
      case "preparation":
        if (
          (details?.is_online && !details?.lesson_url) ||
          (!details?.is_online && !details?.place)
        ) {
          return <LessonPlaceBlock updateLesson={updateLesson} />;
        }
        return <PreparationList />;
      case "lesson_end":
        return (
          <LessonCompleteReportBlock
            handleLessonComplete={handleLessonComplete}
          />
        );
      case "completed":
        return <LessonCompletedBlock />;
      default:
        return null;
    }
  };

  return (
    <CommonLayout isLoading={getLessonIsLoading}>
      <Meta
        title="複業先生 | 授業詳細・進行管理"
        description="成立した授業の詳細情報と進行状況を確認するページです。"
      />

      <LessonInfoSection />
      <Box sx={{ backgroundColor: theme.palette.backgroundColor?.main }}>
        <Container
          maxWidth="lg"
          sx={{
            width: "100%",
            padding: { xs: 0, sm: "1.5rem" }
          }}
        >
          <CustomTabSection
            displayTabs={!isMobile}
            tabs={[
              {
                Icon: <EditNoteOutlinedIcon />,
                labelName: "授業の進行管理",
                tabPanelContents: (
                  <>
                    {getTaskBlock()}
                    <LessonFlowSection
                      updateLesson={updateLesson}
                      handleDeleteSlide={handleDeleteSlide}
                      handleUpdateSlide={handleUpdateSlide}
                      handleUploadSlide={handleUploadSlide}
                    />
                  </>
                )
              },
              {
                Icon: (
                  <Badge
                    badgeContent={lessonRequestComments?.unread_messages_count}
                    color="primary"
                  >
                    <ChatOutlinedIcon />
                  </Badge>
                ),
                labelName: "学校とのメッセージ",
                tabPanelContents: (
                  <Chat
                    postRequestComments={postRequestComments}
                    deleteRequestComments={deleteRequestComments}
                    logicalDeleteUnreadMessages={logicalDeleteUnreadMessages}
                    variant="teacher"
                  />
                )
              }
            ]}
          />
        </Container>
      </Box>
      {/* 授業完了時に表示 */}
      <UploadSlideModal
        {...uploadSlideModal}
        title="授業で利用したスライドを公開することで授業依頼を増やしましょう"
        handleUpload={handleUploadSlide}
        isFinished={!!details?.lesson_completed_at}
      />
      {/* 授業完了時に表示 */}
      <UpdateSlideModal
        title="授業ページにてアップロードしたスライドを公開することで授業依頼を増やしましょう"
        {...updateSlideModal}
        handleUpdate={handleUpdateSlide}
        handleDelete={() => {
          handleDeleteSlide();
          updateSlideModal.onClose();
        }}
        isFinished={!!details?.lesson_completed_at}
        selectIsPublic
      />
    </CommonLayout>
  );
};

export default Show;
