import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import dayjs from "libs/dayjs";

import HeaderLogo from "components/atoms/HeaderLogo";

import type React from "react";

import FooterMenuItem from "components/molecules/FooterMenuItem";

const Footer: React.FC = () => {
  const theme = useTheme();

  return (
    <Box component="footer" sx={{ margin: "2rem auto" }}>
      <div>
        <Box
          component="ul"
          sx={{
            margin: "1.2rem auto",
            padding: "0",
            textAlign: "center",
            fontSize: ".9rem",
            lineHeight: 2
          }}
        >
          {/* MEMO: 後ほど外部でも正常にアクセスできるか確認 */}
          <FooterMenuItem href="https://lxdesign.me/" text="運営会社" />
          <FooterMenuItem
            href="/fukugyosensei_kiyaku.pdf"
            text="利用規約(複業先生向け)"
          />
          <FooterMenuItem
            href="/fukugyosensei_school_kiyaku.pdf"
            text="利用規約(学校向け)"
          />
          <FooterMenuItem
            href="/privacy_policy.pdf"
            text="プライバシーポリシー"
          />
          <FooterMenuItem
            href="https://lofty-book-84d.notion.site/482fc437ac1c411881f7655387dac625"
            text="ヘルプページ"
          />
          <FooterMenuItem href="/contact" text="お問い合わせ" />
        </Box>
      </div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "1.5rem auto"
        }}
      >
        <HeaderLogo />
      </Box>
      <Typography
        color={theme.palette.sub.main}
        fontSize=".9rem"
        sx={{ lineHeight: 1 }}
        align="center"
      >
        © {dayjs().year()} 複業先生
      </Typography>
    </Box>
  );
};

export default Footer;
