import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React, { useEffect } from "react";

import { useWatch, useFormContext } from "react-hook-form";

import IndicatePublic from "components/atoms/fieldLabels/IndicatePublic";
import FormLabel from "components/atoms/FormLabel";
import FormTextField from "components/molecules/FormTextField";

type FormFullNameFieldProps = {
  label: string;
  isRequired?: boolean;
  isPublic?: boolean;
};

const FormFullNameField: React.FC<FormFullNameFieldProps> = (props) => {
  const { label, isRequired, isPublic } = props;
  const lastName = useWatch({ name: "last_name" });
  const firstName = useWatch({ name: "first_name" });
  const lastNameKana = useWatch({ name: "last_name_kana" });
  const firstNameKana = useWatch({ name: "first_name_kana" });

  const { setValue } = useFormContext();

  useEffect(() => {
    setValue("name", `${lastName}${firstName}`);
  }, [lastName, firstName]);

  useEffect(() => {
    setValue("kana_name", `${lastNameKana}${firstNameKana}`);
  }, [lastNameKana, firstNameKana]);

  return (
    <Box>
      <FormLabel labelName={label} isRequired={isRequired} />
      <Grid container>
        <Grid item xs={6} sx={{ padding: ".5rem" }}>
          <FormTextField
            name="last_name"
            label=""
            noLabel
            placeholder="姓：山田"
          />
        </Grid>
        <Grid item xs={6} sx={{ padding: ".5rem" }}>
          <FormTextField
            name="first_name"
            label=""
            noLabel
            placeholder="名：花子"
          />
        </Grid>
        <Grid item xs={6} sx={{ padding: ".5rem" }}>
          <FormTextField
            name="last_name_kana"
            label=""
            noLabel
            placeholder="セイ：ヤマダ"
          />
        </Grid>
        <Grid item xs={6} sx={{ padding: ".5rem" }}>
          <FormTextField
            name="first_name_kana"
            label=""
            noLabel
            placeholder="メイ：ハナコ"
          />
        </Grid>
      </Grid>
      {isPublic && <IndicatePublic />}
    </Box>
  );
};

export default FormFullNameField;
