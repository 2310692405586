import * as yup from "yup";
import { Methods } from "api/api/v1/teacher_profiles";
import {
  katakanaSchema,
  phoneNumberSchema,
  yearDateSchema
} from "utils/yupUtils";

export type NewProfileFormSchemaType = {
  first_name: string;
  last_name: string;
  first_name_kana: string;
  last_name_kana: string;
} & Methods["post"]["reqBody"];

// MEMO: ここにfirst_nameとlast_nameの入力を要求するように変更
const newProfileFormSchema: yup.ObjectSchema<NewProfileFormSchemaType> = yup
  .object()
  .shape({
    name: yup.string().required("必須の項目です"),
    first_name: yup.string().required("必須の項目です"),
    last_name: yup.string().required("必須の項目です"),
    kana_name: katakanaSchema.required("必須の項目です"),
    first_name_kana: katakanaSchema.required("必須の項目です"),
    last_name_kana: katakanaSchema.required("必須の項目です"),
    phone_number: phoneNumberSchema.required("必須の項目です"),
    origin_prefecture_id: yup.number().required("必須の項目です"),
    residence_prefecture_id: yup.number().required("必須の項目です"),

    organization_name: yup.string().optional(),
    birthday: yearDateSchema.required("必須の項目です"),
    introduction: yup.string().required("必須の項目です"),
    enthusiasm: yup
      .string()
      .max(50, "50文字以内にしてください")
      .required("必須の項目です"),
    image: yup.string().required("必須の項目です"),
    image_file_name: yup.string().required("必須の項目です"),
    websites: yup
      .array()
      .optional()
      .of(
        yup.object().shape({
          title: yup.string().optional(),
          url: yup.string().url("正しいURLを入力してください。").optional()
        })
      ),
    teaching_licenses: yup
      .array()
      .optional()
      .of(
        yup.object().shape({
          id: yup.number().optional(),
          special_subject: yup.string().optional()
        })
      ),
    license: yup.string().optional(),
    // MEMO: 以下のバリデーションが効いてなさそう
    tag_ids: yup
      .array()
      .of(yup.number().required("必須の項目です"))
      .min(1, "必須の項目です")
      .required("必須の項目です"),
    is_reward: yup.boolean().required("必須の項目です"),
    can_interview: yup.boolean().optional(),
    is_limited_public: yup.boolean().required("必須の項目です"),
    is_mail_magazine: yup.boolean().required("必須の項目です"),
    lesson_contents: yup
      .string()
      .max(250, "250文字以内にしてください")
      .required("必須の項目です"),
    know_from: yup.number().optional(),
    origin_school_name: yup.string().optional(),
    origin_school_code: yup.string().optional(),
    origin_high_school_name: yup.string().optional(),
    origin_high_school_code: yup.string().optional(),
    origin_graduate_school_name: yup.string().optional()
  });

export default newProfileFormSchema;
