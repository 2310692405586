import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { customColors } from "themes/customColors";
import TopPageTitle from "features/root/index/molecules/TopPageTitle";
import CaseItem from "features/root/index/molecules/CaseItem";
import { CASE_ITEMS } from "../constants";

const Case: React.FC = () => {
  const theme = useTheme();
  const isPc = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        padding: isPc ? "80px" : "24px",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Stack
        spacing={6}
        sx={{
          backgroundColor: customColors.background.gray,
          width: "100%",
          padding: isPc ? "80px" : "48px 16px",
          borderRadius: "8px"
        }}
      >
        <TopPageTitle title="CASE" subText={["実際の授業の様子"]} grey />
        <Stack spacing={3} direction={isPc ? "row" : "column"}>
          {CASE_ITEMS.map((item, index) => (
            <CaseItem {...item} key={index} />
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};

export default Case;
