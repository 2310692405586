import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { Methods } from "api/api/v1/teacher_profiles/new";

import { NewProfileFormSchemaType } from "./constants/newProfileFormSchema";

type PrefectureType = Methods["get"]["resBody"]["prefectures"][number];

type TagType = Methods["get"]["resBody"]["tags"][number];

export const prefectures = atom<PrefectureType[]>([]);
export const tags = atom<TagType[]>([]);

// MEMO: LocalStorageへのプロフィール一時保存用処理を追加
export const temporarilySavedProfileAtom =
  atomWithStorage<NewProfileFormSchemaType | null>(
    "temporarilySavedProfile",
    null,
    undefined,
    {
      getOnInit: true
    }
  );
export const temporarilySavedAtAtom = atomWithStorage<string | null>(
  "temporarySavedAt",
  null,
  undefined,
  {
    getOnInit: true
  }
);
