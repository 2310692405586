import type { NewProfileFormSchemaType } from "./newProfileFormSchema";

export type StepType = {
  required: (keyof NewProfileFormSchemaType)[];
  optional: (keyof NewProfileFormSchemaType)[];
  title?: string;
};

const newProfileFormSteps: StepType[] = [
  {
    // MEMO: ここにfirst_nameとlast_nameの入力を要求するように変更
    required: [
      "name",
      "first_name",
      "last_name",
      "kana_name",
      "first_name_kana",
      "last_name_kana",
      "phone_number",
      "birthday",
      "residence_prefecture_id",
      "origin_prefecture_id",
      "is_limited_public",
      "is_reward"
    ],
    optional: [],
    title: "基本情報の入力をお願いします"
  },
  {
    required: [
      "introduction",
      "tag_ids",
      "enthusiasm",
      "lesson_contents",
      "image",
      "is_mail_magazine",
      "know_from"
    ],
    optional: [],
    title: "プロフィールの作成をお願いします"
  },
  {
    required: [],
    optional: [
      "organization_name",
      "know_from",
      "origin_high_school_name",
      "origin_school_name",
      "origin_graduate_school_name",
      "license",
      "teaching_licenses",
      "can_interview",
      "websites"
    ],
    title: "その他の経歴を埋めることで選ばれやすくなります"
  }
];

export default newProfileFormSteps;
