import ProcedureCard from "features/root/index/molecules/ProcedureCard";
import TopPageTitle from "features/root/index/molecules/TopPageTitle";

import type React from "react";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { PROCEDURE_ITEMS } from "../constants";

const Procedures: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        padding: 3,
        alignItems: "center",
        justifyContent: "center",
        mb: isMobile ? "48px" : "120px"
      }}
    >
      <TopPageTitle
        title="LET'S HAVE A LESSON"
        subText={["まずは登録して複業先生を見てみましょう"]}
      />
      <Grid container spacing={3} maxWidth={960}>
        {PROCEDURE_ITEMS.map((item, index) => (
          <Grid item xs={12} sm={6} lg={3} key={index}>
            <ProcedureCard
              number={index + 1}
              image={item.image}
              title={item.title}
              desc={item.description}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Procedures;
